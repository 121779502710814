<template>
  <div>
    <page-appbar />
    <v-overlay v-if="isSignIn" :dark="this.$vuetify.theme.dark">
      <v-card>
        <v-hover v-slot="{ hover }">
          <v-icon
            @click="isSignIn = false"
            :color="hover ? 'primary' : ''"
            style="position: absolute; top: 8px; right: 8px"
          >
            mdi-close
          </v-icon>
        </v-hover>
        <sign-in />
      </v-card>
    </v-overlay>
    <router-view
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 60px' : 'margin-top: 52px'
      "
    />
    <page-footer />
  </div>
</template>
<style>
html {
  overscroll-behavior: none;
  overflow: scroll;
}
</style>
<script>
import PageAppbar from "../../components/Appbar/PageAppbar.vue";
import PageFooter from "../../components/Footer/PageFooter.vue";
import SignIn from "../../components/Authorization/signIn.vue";
export default {
  components: {
    PageAppbar,
    PageFooter,
    SignIn,
  },
  data() {
    return {
      isSignIn: false,
    };
  },
  methods: {
    openSignIn() {
      this.isSignIn = true;
    },
  },
};
</script>

<style></style>
