const rules = {
  required: (v) => !!v || "rule.required",
  email: (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || "rule.email",
  name: (v) => /(^[가-힣]+$)|(^[a-zA-Z]+$)/.test(v) || "rule.name",
  phone: (v) =>
    /(?!^.*0000.*$)((^\d{2,3}-\d{3,4}-\d{4}$)|(^\d{9,11}$))/.test(v) ||
    "rule.phone",
  emailOrPhone: (v) =>
    /(?!^.*0000.*$)((^\d{2,3}-\d{3,4}-\d{4}$)|(^\d{9,11}$))|(.+@.+)/.test(v) ||
    "rule.emailOrPhone",
  verify: (v) => /^[0-9]{6}$/.test(v) || "rule.verify",
  loginPassword: (v) =>
    /^[0-9a-zA-Z!@#$%^*+=-_]{6,20}$/.test(v) || "rule.loginPassword",
  signUpPassword: (v) =>
    /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-_])(?=.*[0-9]).{8,20}$/.test(v) ||
    "rule.signUpPassword",
  phoneWithCountry: (phone, code) => {
    //휴대전화 번호 -> 국가번호로 바꾸는 함수인데
    //나중에 signIn이나 signUp같은데 있는 전화번호 처리 코드도 이걸로 처리하면 좋을듯?
    if (
      phone.match(/(^\d{2,3}-\d{3,4}-\d{4}$)|(^\d{9,11}$)|(^\+\d[10-14]$)/) ==
      false
    ) {
      return undefined;
    }

    if (phone.match(/^\d{2,3}-\d{3,4}-\d{4}$/)) {
      phone = phone.replaceAll(/-/g, "");
    }
    if (phone.match(/^\d{9,11}$/)) {
      phone =
        (code.startsWith("+") ? "" : "+") +
        code +
        (phone.startsWith("0") ? phone.slice(1) : phone);
    }
    return phone;
  },
};

export default rules;
