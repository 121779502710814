<template>
  <v-card
    width="520"
    rounded="lg"
    :color="
      $vuetify.breakpoint.mdAndUp
        ? $vuetify.theme.dark
          ? 'background_normal'
          : 'background_light'
        : 'transparent'
    "
    flat
    :style="
      $vuetify.breakpoint.mdAndUp
        ? 'padding: 70px 60px'
        : $vuetify.breakpoint.xs
        ? 'padding: 40px 20px'
        : 'padding: 60px 46px'
    "
  >
    <v-container v-if="loginLoading" class="pt-15">
      <v-card class="text-center" flat color="transparent">
        <v-progress-circular color="primary" indeterminate />
        <v-card-title class="justify-center font-weight-bold title">
          {{ $t("login.wait") }}
        </v-card-title>
      </v-card>
    </v-container>

    <v-container v-else class="pa-0">
      <div class="mx-auto">
        <v-form
          v-model="valid"
          ref="form"
          class="d-flex flex-column"
          style="gap: 40px"
        >
          <div class="d-flex flex-column align-center" style="gap: 8px">
            <span class="h3 bold onSurface_dark--text">
              {{ $t("login.title") }}
            </span>
            <span class="title-2 regular onSurface_light--text">
              {{ $t("login.subtitle") }}
            </span>
          </div>
          <div class="d-flex flex-column pa-0" style="gap: 12px">
            <v-select
              v-model="form.country"
              :items="countries"
              item-text="name"
              item-value="name"
              :rules="[rules.required]"
              class="select"
              attach=".select"
              hide-details="auto"
              outlined
              solo
              loader-height="100"
              flat
              :background-color="
                $vuetify.theme.dark ? 'background_normal' : 'background_light'
              "
              height="53"
              :menu-props="{
                maxHeight: 250,
                offsetY: true,
                contentClass: 'elevation-1',
              }"
              @change="onSelectChange"
            >
              <template slot="label">
                <span
                  class="title-2 regular"
                  :class="
                    $vuetify.theme.dark
                      ? 'onSurface_normal--text'
                      : 'background_dark--text'
                  "
                >
                  {{ $t("login.region") }}
                </span>
              </template>
              <template slot="append">
                <v-icon size="16" color="onSurface_light">
                  mdi-chevron-down
                </v-icon>
              </template>
              <template v-slot:selection="{ item }">
                <div class="d-flex flex-column" style="gap: 6px">
                  <span class="primary--text subtitle-2 regular">
                    {{ $t("login.region") }}
                  </span>
                  <div class="d-flex" style="gap: 6px">
                    <v-img max-width="22" :src="flagFileName(item)" />
                    <span class="text-capitalize onSurface_dark--text">
                      {{ $i18n.locale == "ko" ? item.name_kor : item.name }}
                      ({{ item.code }})
                    </span>
                  </div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div
                  class="d-flex align-center"
                  style="gap: 6px; max-width: 100%"
                >
                  <v-img
                    max-width="22"
                    max-height="16"
                    :src="flagFileName(item)"
                  />
                  <span class="text-capitalize onSurface_dark--text">
                    {{
                      $i18n.locale == "ko"
                        ? `${item.name_kor} (${item.code}) (${item.name})`
                        : `${item.name} (${item.code})`
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:message="{ message }">
                <span>{{ $t(message) }}</span>
              </template>
            </v-select>
            <v-text-field
              v-model="form.phoneNumber"
              :rules="[rules.required, rules.emailOrPhone]"
              required
              hide-details="auto"
              solo
              flat
              dense
              outlined
              :background-color="
                $vuetify.theme.dark ? 'background_normal' : 'background_light'
              "
              height="53"
            >
              <template slot="label">
                <span
                  class="title-2 regular"
                  :class="
                    $vuetify.theme.dark
                      ? 'onSurface_normal--text'
                      : 'background_dark--text'
                  "
                >
                  {{ $t("login.phone") }}
                </span>
              </template>
              <template v-slot:message="{ message }">
                <span>{{ $t(message) }}</span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="form.password"
              :rules="[rules.required, rules.loginPassword]"
              :type="show2 ? 'text' : 'password'"
              required
              hide-details="auto"
              single-line
              flat
              dense
              solo
              outlined
              height="53"
              loader-height="99"
              :background-color="
                $vuetify.theme.dark ? 'background_normal' : 'background_light'
              "
              @keydown.enter="
                if (valid) {
                  signInWithPassword();
                }
              "
              @click:append="show2 = !show2"
            >
              <template slot="label">
                <span
                  class="title-2 regular"
                  :class="
                    $vuetify.theme.dark
                      ? 'onSurface_normal--text'
                      : 'background_dark--text'
                  "
                  >{{ $t("login.password") }}</span
                >
              </template>
              <template slot="append">
                <v-icon
                  size="15"
                  class="my-auto"
                  color="onSurface_light"
                  @click="show2 = !show2"
                  >{{ show2 ? "mdi-eye-off" : "mdi-eye" }}</v-icon
                >
              </template>
              <template v-slot:message="{ message }">
                <span>{{ $t(message) }}</span>
              </template>
            </v-text-field>
            <div class="text-center">
              <v-btn
                @click="signInWithPassword"
                color="primary"
                :disabled="!valid"
                depressed
                block
                height="53"
                class="bold title-1 rounded-md"
              >
                <span class="white_light_global--text">{{
                  $t("login.signInBtn")
                }}</span>
              </v-btn>
            </div>
            <!-- <v-checkbox
              v-model="form.checkbox"
              dense
              hide-details
              :ripple="false"
              class="my-0"
            >
              <template slot="label">
                <span class="onSurface_normal--text title-2 regular">{{
                  $t("login.keepSignedIn")
                }}</span>
              </template>
            </v-checkbox> -->
            <div
              @click="form.checkbox = !form.checkbox"
              class="d-flex align-center hover-pointer"
            >
              <v-icon
                :color="form.checkbox ? 'primary' : 'onSurface_normal'"
                size="18"
                >{{
                  form.checkbox
                    ? "mdi-checkbox-marked"
                    : "mdi-checkbox-blank-outline"
                }}
              </v-icon>
              <span class="ml-1">{{ $t("login.keepSignedIn") }}</span>
            </div>
          </div>

          <div
            class="d-flex justify-center title-2 regular onSurface_dark--text"
            :style="$vuetify.breakpoint.mdAndUp ? 'gap: 40px' : ''"
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-4'"
          >
            <span
              class="regular onSurface_dark--text hover-pointer mx-auto"
              @click="$emit('changeType', 2)"
              :class="$vuetify.breakpoint.smAndUp ? 'title-2' : 'subtitle-2'"
            >
              {{ $t("login.reset") }}
            </span>
            <v-divider
              vertical
              class="my-auto onSurface_light"
              style="height: 12px"
            />
            <span
              class="regular onSurface_dark--text hover-pointer mx-auto"
              @click="$router.push('/signup')"
              :class="$vuetify.breakpoint.smAndUp ? 'title-2' : 'subtitle-2'"
            >
              {{ $t("login.register") }}
            </span>
          </div>

          <div class="pa-0 d-flex align-center" style="gap: 10px">
            <v-divider />
            <span class="subtitle-1 regular onSurface_dark--text">
              {{ $t("login.snsLogin") }}
            </span>
            <v-divider />
          </div>
          <!-- SNS 계정 로그인 -->
          <div class="d-flex justify-center pa-0" style="gap: 52px">
            <!-- signInWithGoogle -->
            <v-btn
              @click="signInWithGoogle"
              depressed
              icon
              width="52"
              height="52"
              class="background_normal"
            >
              <v-avatar size="50" color="white_light_global">
                <v-img
                  max-width="38"
                  max-height="38"
                  :src="require('@/assets/logos/auth/google.png')"
                />
              </v-avatar>
            </v-btn>
            <!-- signInWithKakao -->
            <v-btn
              @click="signInWithKakao"
              icon
              depressed
              width="52"
              height="52"
            >
              <v-avatar size="50" color="#fee500">
                <v-img :src="require('@/assets/logos/auth/kakao.png')" />
              </v-avatar>
            </v-btn>
            <!-- signInWithNaver -->
            <v-btn
              @click="signInWithNaver"
              icon
              depressed
              width="52"
              height="52"
            >
              <v-avatar size="50" color="#00c93b">
                <v-img
                  max-width="41"
                  max-height="41"
                  :src="require('@/assets/logos/auth/naver.png')"
                />
              </v-avatar>
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-container>
  </v-card>
</template>
<style scoped>
.recaptcha-terms-text {
  font-size: 12px;
  font-weight: 200;
  color: #637282;
}

::v-deep .theme--light .v-input__slot fieldset {
  border-color: var(--v-background_dark-base);
}

::v-deep .theme--dark .v-input__slot fieldset {
  border-color: var(--v-onSurface_light-base);
}

::v-deep .v-input--selection-controls__input i {
  font-size: 16px !important;
}

::v-deep .v-input--selection-controls__input {
  width: 16px;
  height: 16px;
}
</style>
<script>
import API from "../../API/auth";
import countries from "@/assets/flags/flags.js";
import rules from "@/utils/rules.js";

export default {
  data() {
    return {
      rules: rules,
      countries: countries,
      show2: false,
      valid: false,
      loginLoading: false,
      form: {
        country: undefined,
        phoneNumber: "",
        password: "",
        checkbox: true,
      },
    };
  },
  mounted() {
    API.getCountry().then((res) => {
      let userCountry = countries.find(
        (el) => el.countryCode == res.data.countryCode,
      );
      this.form.country = userCountry.name;
    });
  },
  methods: {
    /**
     * 국가 선택을 바탕으로 자동으로 페이지 언어를 변환합니다.
     */
    onSelectChange(select) {
      this.$i18n.locale = select.includes("korea") ? "ko" : "en";
    },
    /**
     * 국가 플래그 이미지 파일명을 생성합니다.
     * @param {Object} item 국가 정보 객체
     * @returns {String} 국가 플래그 이미지의 경로
     */
    flagFileName(item) {
      let filename = item.flag || item.name;
      filename = filename.replaceAll(" ", "-");
      return require("@/assets/flags/" + filename + ".svg");
    },
    /**
     * Google 계정으로 로그인을 시도합니다.
     */
    async signInWithGoogle() {
      this.loginLoading = true;
      API.signInWithGoogle(this.form.checkbox).catch((e) => {
        this.$toasted.global.error(this.$t("login." + e));
        this.loginLoading = false;
      });
    },
    /**
     * Kakao 계정으로 로그인을 시도합니다.
     */
    signInWithKakao() {
      this.loginLoading = true;
      API.redirectToKakao(this.form.checkbox).catch((e) => {
        this.$toasted.global.error(this.$t("login." + e));
        this.loginLoading = false;
      });
    },
    /**
     * Naver 계정으로 로그인을 시도합니다.
     */
    signInWithNaver() {
      API.redirectToNaver(this.form.checkbox).catch((e) => {
        this.$toasted.global.error(this.$t("login." + e));
        this.loginLoading = false;
      });
    },
    /**
     * 비밀번호를 사용하여 로그인을 시도합니다.
     * 이메일 또는 전화번호를 지원합니다.
     */
    signInWithPassword() {
      //이메일로 로그인 할 경우
      if (this.form.phoneNumber.match(/.+@.+/)) {
        this.loginLoading = true;

        API.signInWithPassword(
          undefined,
          this.form.phoneNumber,
          this.form.password,
          this.form.checkbox,
        )
          .then(() => {
            this.loginLoading = false;
          })
          .catch(() => {
            this.loginLoading = false;
          });

        return;
      }
      //연락처로 로그인 전 정규식
      this.form.phoneNumber = this.form.phoneNumber.replaceAll(/-/g, "");

      const country = this.countries.find((el) => el.name == this.form.country);
      if (country == undefined) {
        console.error("Can't find country in datas.");
        return;
      }

      const code = country.code;
      let authPhoneNumber = this.form.phoneNumber;

      if (authPhoneNumber.startsWith("0")) {
        // 국가 코드가 +82(대한민국)인 경우, 또는 다른 국가이지만 전화번호가 '0'으로 시작하는 경우 '0' 제거
        // '0'으로 시작하는 번호는 일반적이지 않으므로 조건을 간소화할 수 있습니다.
        authPhoneNumber = authPhoneNumber.slice(1);
      }
      authPhoneNumber = code + authPhoneNumber;

      this.loginLoading = true;
      API.signInWithPassword(
        authPhoneNumber,
        undefined,
        this.form.password,
        this.form.checkbox,
      )
        .then(() => {
          this.loginLoading = false;
        })
        .catch(() => {
          this.loginLoading = false;
        });
    },
    // 전화번호로 로그인
    async signInWithPhone() {
      if (this.form.phone.match(/^\d{3}-\d{3,4}-\d{4}$/)) {
        let temp = this.form.phone.replace(/-/g, "");
        this.form.phone = "+82" + temp.slice(1);
      } else if (this.form.phone.match(/^\d{10,11}$/)) {
        this.form.phone = "+82" + this.form.phone.slice(1);
      } else if (this.form.phone.match(/^\+[0-9](?:[- ]?[0-9]){,14}$/)) {
        return this.$toasted.global.error("올바르지 않은 전화번호입니다.");
      }

      if (!this.form.password)
        return this.$toasted.global.error("비밀번호를 입력해 주세요.");

      this.loginLoading = true;
      this.$axios
        .patch("/auth/getUserWithPhone", { phone: this.form.phone })
        .then((user) => {
          console.log(user);
          API.signInWithFirebase(user.data.email, this.form.password)
            .then(() => {
              this.loginLoading = false;
            })
            .catch((e) => {
              this.loginLoading = false;
              console.log(e);
              return this.$toasted.global.error(
                "API에서 문제가 발생했습니다: " + e.data.code,
              );
            });
        })
        .catch((error) => {
          this.loginLoading = false;
          return this.$toasted.global.error(
            "알 수 없는 문제가 발생했습니다: " + error.data.code,
          );
        });
    },
  },
};
</script>
