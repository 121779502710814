export default [
  {
    name: "korea, republic of",
    name_kor: "대한민국",
    code: "+82",
    flag: "korea",
    countryCode: "KR",
  },
  {
    name: "afghanistan",
    name_kor: "아프가니스탄",
    code: "+93",
    countryCode: "AF",
  },
  { name: "albania", name_kor: "알바니아", code: "+355", countryCode: "AL" },
  { name: "algeria", name_kor: "알제리", code: "+213", countryCode: "DZ" },
  { name: "andorra", name_kor: "안도라", code: "+376", countryCode: "AD" },
  { name: "angola", name_kor: "앙골라", code: "+244", countryCode: "AO" },
  {
    name: "antigua and barbuda",
    name_kor: "앤티가 바부다",
    code: "+1",
    countryCode: "AG",
  },
  { name: "argentina", name_kor: "아르헨티나", code: "+54", countryCode: "AR" },
  { name: "armenia", name_kor: "아르메니아", code: "+374", countryCode: "AM" },
  { name: "aruba", name_kor: "아루바", code: "+297", countryCode: "AW" },
  {
    name: "australia",
    name_kor: "오스트레일리아",
    code: "+61",
    countryCode: "AU",
  },
  { name: "austria", name_kor: "오스트리아", code: "+43", countryCode: "AT" },
  {
    name: "azerbaijan",
    name_kor: "아제르바이잔",
    code: "+994",
    countryCode: "AZ",
  },
  { name: "bahamas", name_kor: "바하마", code: "+1", countryCode: "BS" },
  { name: "bahrain", name_kor: "바레인", code: "+973", countryCode: "BH" },
  {
    name: "bangladesh",
    name_kor: "방글라데시",
    code: "+880",
    countryCode: "BD",
  },
  { name: "barbados", name_kor: "바베이도스", code: "+1", countryCode: "BB" },
  { name: "belarus", name_kor: "벨라루스", code: "+375", countryCode: "BY" },
  { name: "belgium", name_kor: "벨기에", code: "+32", countryCode: "BE" },
  { name: "belize", name_kor: "벨리즈", code: "+501", countryCode: "BZ" },
  { name: "benin", name_kor: "베냉", code: "+229", countryCode: "BJ" },
  { name: "bhutan", name_kor: "부탄", code: "+975", countryCode: "BT" },
  { name: "bolivia", name_kor: "볼리비아", code: "+591", countryCode: "BO" },
  {
    name: "bosnia and herzegovina",
    name_kor: "보스니아",
    code: "+387",
    countryCode: "BA",
  },
  { name: "botswana", name_kor: "보츠와나", code: "+267", countryCode: "BW" },
  {
    name: "bouvet island",
    name_kor: "부베 섬",
    code: "+55",
    countryCode: "BV",
  },
  { name: "brazil", name_kor: "브라질", code: "+55", countryCode: "BR" },
  {
    name: "brunei darussalam",
    name_kor: "브루나이",
    code: "+673",
    countryCode: "BN",
  },
  { name: "bulgaria", name_kor: "불가리아", code: "+359", countryCode: "BG" },
  {
    name: "burkina faso",
    name_kor: "부르키나파소",
    code: "+226",
    countryCode: "BF",
  },
  { name: "burundi", name_kor: "부룬디", code: "+257", countryCode: "BI" },
  { name: "cambodia", name_kor: "캄보디아", code: "+855", countryCode: "KH" },
  { name: "cameroon", name_kor: "카메룬", code: "+237", countryCode: "CM" },
  { name: "canada", name_kor: "캐나다", code: "+1", countryCode: "CA" },
  {
    name: "cape verde",
    name_kor: "카보베르데",
    code: "+238",
    countryCode: "CV",
  },
  {
    name: "central african republic",
    name_kor: "중앙아프리카 공화국",
    code: "+236",
    countryCode: "CF",
  },
  { name: "chad", name_kor: "차드", code: "+235", countryCode: "TD" },
  { name: "chile", name_kor: "칠레", code: "+56", countryCode: "CL" },
  { name: "china", name_kor: "중국", code: "+86", countryCode: "CN" },
  { name: "colombia", name_kor: "콜롬비아", code: "+57", countryCode: "CO" },
  { name: "comoros", name_kor: "코모로", code: "+269", countryCode: "KM" },
  { name: "congo", name_kor: "콩고", code: "+242", countryCode: "CG" },
  {
    name: "costa rica",
    name_kor: "코스타리카",
    code: "+506",
    countryCode: "CR",
  },
  { name: "croatia", name_kor: "크로아티아", code: "+385", countryCode: "HR" },
  { name: "cuba", name_kor: "쿠바", code: "+53", countryCode: "CU" },
  { name: "cura ao", name_kor: "퀴라소", code: "+599", countryCode: "CW" },
  { name: "cyprus", name_kor: "키프로스", code: "+357", countryCode: "CY" },
  { name: "czech republic", name_kor: "체코", code: "+420", countryCode: "CZ" },
  { name: "denmark", name_kor: "덴마크", code: "+45", countryCode: "DK" },
  { name: "djibouti", name_kor: "지부티", code: "+253", countryCode: "DJ" },
  { name: "dominica", name_kor: "도미니카", code: "+1", countryCode: "DM" },
  {
    name: "dominican republic",
    name_kor: "도미니카 공화국",
    code: "+1",
    countryCode: "DO",
  },
  { name: "ecuador", name_kor: "에콰도르", code: "+593", countryCode: "EC" },
  { name: "egypt", name_kor: "이집트", code: "+20", countryCode: "EG" },
  {
    name: "el salvador",
    name_kor: "엘살바도르",
    code: "+503",
    countryCode: "SV",
  },
  {
    name: "equatorial guinea",
    name_kor: "적도 기니",
    code: "+240",
    countryCode: "GQ",
  },
  { name: "eritrea", name_kor: "에리트레아", code: "+291", countryCode: "ER" },
  { name: "estonia", name_kor: "에스토니아", code: "+372", countryCode: "EE" },
  { name: "eswatini", name_kor: "에스와티니", code: "+268", countryCode: "SZ" },
  { name: "ethiopia", name_kor: "에티오피아", code: "+251", countryCode: "ET" },

  { name: "fiji", name_kor: "피지", code: "+679", countryCode: "FJ" },
  { name: "finland", name_kor: "핀란드", code: "+358", countryCode: "FI" },
  { name: "france", name_kor: "프랑스", code: "+33", countryCode: "FR" },

  { name: "gabon", name_kor: "가봉", code: "+241", countryCode: "GA" },
  { name: "gambia", name_kor: "감비아", code: "+220", countryCode: "GM" },
  { name: "georgia", name_kor: "조지아", code: "+995", countryCode: "GE" },
  { name: "germany", name_kor: "독일", code: "+49", countryCode: "DE" },
  { name: "ghana", name_kor: "가나", code: "+233", countryCode: "GH" },
  { name: "greece", name_kor: "그리스", code: "+30", countryCode: "GR" },
  { name: "grenada", name_kor: "그레나다", code: "+1", countryCode: "GD" },
  { name: "guatemala", name_kor: "과테말라", code: "+502", countryCode: "GT" },
  { name: "guinea", name_kor: "기니", code: "+224", countryCode: "GN" },
  {
    name: "guinea-bissau",
    name_kor: "기니비사우",
    code: "+245",
    countryCode: "GW",
  },
  { name: "guyana", name_kor: "가이아나", code: "+592", countryCode: "GY" },
  { name: "haiti", name_kor: "아이티", code: "+509", countryCode: "HT" },
  { name: "honduras", name_kor: "온두라스", code: "+504", countryCode: "HN" },
  { name: "hong kong", name_kor: "홍콩", code: "+852", countryCode: "HK" },
  { name: "hungary", name_kor: "헝가리", code: "+36", countryCode: "HU" },
  { name: "iceland", name_kor: "아이슬란드", code: "+354", countryCode: "IS" },
  { name: "india", name_kor: "인도", code: "+91", countryCode: "IN" },
  { name: "indonesia", name_kor: "인도네시아", code: "+62", countryCode: "ID" },
  {
    name: "iran",
    name_kor: "이란",
    code: "+98",
    countryCode: "IR",
  },
  { name: "iraq", name_kor: "이라크", code: "+964", countryCode: "IQ" },
  { name: "ireland", name_kor: "아일랜드", code: "+353", countryCode: "IE" },
  { name: "israel", name_kor: "이스라엘", code: "+972", countryCode: "IL" },
  { name: "italy", name_kor: "이탈리아", code: "+39", countryCode: "IT" },
  { name: "jamaica", name_kor: "자메이카", code: "+1", countryCode: "JM" },
  { name: "japan", name_kor: "일본", code: "+81", countryCode: "JP" },
  { name: "jordan", name_kor: "요르단", code: "+962", countryCode: "JO" },
  { name: "kazakhstan", name_kor: "카자흐스탄", code: "+7", countryCode: "KZ" },
  { name: "kenya", name_kor: "케냐", code: "+254", countryCode: "KE" },
  { name: "kiribati", name_kor: "키리바시", code: "+686", countryCode: "KI" },
  { name: "kuwait", name_kor: "쿠웨이트", code: "+965", countryCode: "KW" },
  {
    name: "kyrgyzstan",
    name_kor: "키르기스스탄",
    code: "+996",
    countryCode: "KG",
  },
  { name: "laos", name_kor: "라오스", code: "+856", countryCode: "LA" },
  { name: "latvia", name_kor: "라트비아", code: "+371", countryCode: "LV" },
  { name: "lebanon", name_kor: "레바논", code: "+961", countryCode: "LB" },
  { name: "lesotho", name_kor: "레소토", code: "+266", countryCode: "LS" },
  { name: "liberia", name_kor: "라이베리아", code: "+231", countryCode: "LR" },
  { name: "libya", name_kor: "리비아", code: "+218", countryCode: "LY" },
  {
    name: "liechtenstein",
    name_kor: "리히텐슈타인",
    code: "+423",
    countryCode: "LI",
  },
  {
    name: "lithuania",
    name_kor: "리투아니아",
    code: "+370",
    countryCode: "LT",
  },
  {
    name: "luxembourg",
    name_kor: "룩셈부르크",
    code: "+352",
    countryCode: "LU",
  },
  {
    name: "madagascar",
    name_kor: "마다가스카르",
    code: "+261",
    countryCode: "MG",
  },
  { name: "malawi", name_kor: "말라위", code: "+265", countryCode: "MW" },
  { name: "malaysia", name_kor: "말레이시아", code: "+60", countryCode: "MY" },
  { name: "maldives", name_kor: "몰디브", code: "+960", countryCode: "MV" },
  { name: "mali", name_kor: "말리", code: "+223", countryCode: "ML" },
  { name: "malta", name_kor: "몰타", code: "+356", countryCode: "MT" },
  {
    name: "marshall islands",
    name_kor: "마셜 제도",
    code: "+692",
    countryCode: "MH",
  },
  { name: "mauritania", name_kor: "모리타니", code: "+222", countryCode: "MR" },
  { name: "mauritius", name_kor: "모리셔스", code: "+230", countryCode: "MU" },
  { name: "mexico", name_kor: "멕시코", code: "+52", countryCode: "MX" },
  {
    name: "micronesia",
    name_kor: "미크로네시아",
    code: "+691",
    countryCode: "FM",
  },
  { name: "moldova", name_kor: "몰도바", code: "+373", countryCode: "MD" },
  { name: "monaco", name_kor: "모나코", code: "+377", countryCode: "MC" },
  { name: "mongolia", name_kor: "몽골", code: "+976", countryCode: "MN" },
  {
    name: "montenegro",
    name_kor: "몬테네그로",
    code: "+382",
    countryCode: "ME",
  },
  { name: "morocco", name_kor: "모로코", code: "+212", countryCode: "MA" },
  { name: "mozambique", name_kor: "모잠비크", code: "+258", countryCode: "MZ" },
  { name: "myanmar", name_kor: "미얀마", code: "+95", countryCode: "MM" },
  { name: "namibia", name_kor: "나미비아", code: "+264", countryCode: "NA" },
  { name: "nauru", name_kor: "나우루", code: "+674", countryCode: "NR" },
  { name: "nepal", name_kor: "네팔", code: "+977", countryCode: "NP" },
  { name: "netherlands", name_kor: "네덜란드", code: "+31", countryCode: "NL" },
  { name: "new zealand", name_kor: "뉴질랜드", code: "+64", countryCode: "NZ" },
  { name: "nicaragua", name_kor: "니카라과", code: "+505", countryCode: "NI" },
  { name: "niger", name_kor: "니제르", code: "+227", countryCode: "NE" },
  { name: "nigeria", name_kor: "나이지리아", code: "+234", countryCode: "NG" },
  {
    name: "north macedonia",
    name_kor: "북마케도니아",
    code: "+389",
    countryCode: "MK",
  },
  { name: "norway", name_kor: "노르웨이", code: "+47", countryCode: "NO" },
  { name: "oman", name_kor: "오만", code: "+968", countryCode: "OM" },
  { name: "pakistan", name_kor: "파키스탄", code: "+92", countryCode: "PK" },
  { name: "palau", name_kor: "팔라우", code: "+680", countryCode: "PW" },
  { name: "panama", name_kor: "파나마", code: "+507", countryCode: "PA" },
  {
    name: "papua new guinea",
    name_kor: "파푸아뉴기니",
    code: "+675",
    countryCode: "PG",
  },
  { name: "paraguay", name_kor: "파라과이", code: "+595", countryCode: "PY" },
  { name: "peru", name_kor: "페루", code: "+51", countryCode: "PE" },
  { name: "philippines", name_kor: "필리핀", code: "+63", countryCode: "PH" },
  { name: "poland", name_kor: "폴란드", code: "+48", countryCode: "PL" },
  { name: "portugal", name_kor: "포르투갈", code: "+351", countryCode: "PT" },
  { name: "qatar", name_kor: "카타르", code: "+974", countryCode: "QA" },
  { name: "romania", name_kor: "루마니아", code: "+40", countryCode: "RO" },
  { name: "russia", name_kor: "러시아", code: "+7", countryCode: "RU" },
  { name: "rwanda", name_kor: "르완다", code: "+250", countryCode: "RW" },

  {
    name: "saint lucia",
    name_kor: "세인트루시아",
    code: "+1",
    countryCode: "LC",
  },
  { name: "samoa", name_kor: "사모아", code: "+685", countryCode: "WS" },
  { name: "san marino", name_kor: "산마리노", code: "+378", countryCode: "SM" },
  {
    name: "sao tome and principe",
    name_kor: "상투메 프린시페",
    code: "+239",
    countryCode: "ST",
  },
  {
    name: "saudi arabia",
    name_kor: "사우디아라비아",
    code: "+966",
    countryCode: "SA",
  },
  { name: "senegal", name_kor: "세네갈", code: "+221", countryCode: "SN" },
  { name: "serbia", name_kor: "세르비아", code: "+381", countryCode: "RS" },
  { name: "seychelles", name_kor: "세이셸", code: "+248", countryCode: "SC" },
  {
    name: "sierra leone",
    name_kor: "시에라리온",
    code: "+232",
    countryCode: "SL",
  },
  { name: "singapore", name_kor: "싱가포르", code: "+65", countryCode: "SG" },
  { name: "slovakia", name_kor: "슬로바키아", code: "+421", countryCode: "SK" },
  { name: "slovenia", name_kor: "슬로베니아", code: "+386", countryCode: "SI" },
  {
    name: "solomon islands",
    name_kor: "솔로몬 제도",
    code: "+677",
    countryCode: "SB",
  },
  { name: "somalia", name_kor: "소말리아", code: "+252", countryCode: "SO" },
  {
    name: "south africa",
    name_kor: "남아프리카",
    code: "+27",
    countryCode: "ZA",
  },
  { name: "south sudan", name_kor: "남수단", code: "+211", countryCode: "SS" },
  { name: "spain", name_kor: "스페인", code: "+34", countryCode: "ES" },
  { name: "sri lanka", name_kor: "스리랑카", code: "+94", countryCode: "LK" },
  { name: "sudan", name_kor: "수단", code: "+249", countryCode: "SD" },
  { name: "suriname", name_kor: "수리남", code: "+597", countryCode: "SR" },
  { name: "sweden", name_kor: "스웨덴", code: "+46", countryCode: "SE" },
  { name: "switzerland", name_kor: "스위스", code: "+41", countryCode: "CH" },
  { name: "syria", name_kor: "시리아", code: "+963", countryCode: "SY" },
  { name: "taiwan", name_kor: "대만", code: "+886", countryCode: "TW" },
  {
    name: "tajikistan",
    name_kor: "타지키스탄",
    code: "+992",
    countryCode: "TJ",
  },
  { name: "tanzania", name_kor: "탄자니아", code: "+255", countryCode: "TZ" },
  { name: "thailand", name_kor: "태국", code: "+66", countryCode: "TH" },
  { name: "togo", name_kor: "토고", code: "+228", countryCode: "TG" },
  { name: "tonga", name_kor: "통가", code: "+676", countryCode: "TO" },
  {
    name: "trinidad and tobago",
    name_kor: "트리니다드 토바고",
    code: "+1",
    countryCode: "TT",
  },
  { name: "tunisia", name_kor: "튀니지", code: "+216", countryCode: "TN" },
  { name: "turkey", name_kor: "터키", code: "+90", countryCode: "TR" },
  {
    name: "turkmenistan",
    name_kor: "투르크메니스탄",
    code: "+993",
    countryCode: "TM",
  },
  { name: "tuvalu", name_kor: "투발루", code: "+688", countryCode: "TV" },
  { name: "uganda", name_kor: "우간다", code: "+256", countryCode: "UG" },
  { name: "ukraine", name_kor: "우크라이나", code: "+380", countryCode: "UA" },
  {
    name: "united arab emirates",
    name_kor: "아랍에미레이트",
    code: "+971",
    countryCode: "AE",
  },
  { name: "united kingdom", name_kor: "영국", code: "+44", countryCode: "GB" },
  {
    name: "united states of america",
    name_kor: "미국",
    code: "+1",
    countryCode: "US",
  },
  { name: "uruguay", name_kor: "우루과이", code: "+598", countryCode: "UY" },
  {
    name: "uzbekistan",
    name_kor: "우즈베키스탄",
    code: "+998",
    countryCode: "UZ",
  },
  { name: "venezuela", name_kor: "베네수엘라", code: "+58", countryCode: "VE" },
  { name: "vietnam", name_kor: "베트남", code: "+84", countryCode: "VN" },
  { name: "yemen", name_kor: "예멘", code: "+967", countryCode: "YE" },
  { name: "zambia", name_kor: "잠비아", code: "+260", countryCode: "ZM" },
  { name: "zimbabwe", name_kor: "짐바브웨", code: "+263", countryCode: "ZW" },
];
